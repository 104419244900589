<template>
    <component
        :is="components[section.section_path]"
        :attributes="section.attributes || []"
        :collections="collections"
    />
</template>

<script lang="ts" setup>
    import axios from 'axios';

    import { useRoute } from '@/shared/composables/use-route.ts';

    import type { DefineComponent } from 'vue';

    const props = defineProps<{
        section: any;
    }>();

    const { siteRoute } = useRoute();

    const components = {};
    const files = import.meta.glob('../../tenants/*/sections/**/*.vue', { eager: true });

    for (const path in files) {
        const simplifiedPath = path.replace(/^\.\.\/\.\.\//, '').replace(/\.vue$/, '');
        components[simplifiedPath] = (files[path] as DefineComponent).default;
    }

    const collections = ref<{ handle: string, entries: any[] }[]>([]);

    async function fetchEntries(handle: string) {
        const response = await axios.get(siteRoute(`/api/collections/${handle}/entries`));

        collections.value.push({
            handle,
            entries: response.data.data,
        });
    }

    onMounted(() => {
        props.section.collections.forEach(collection => fetchEntries(collection.handle));
    });
</script>
