<template>
    <main class="text-angus max-w-screen relative font-[barlow]">
        <section
            id="accueil"
            class="bg-mayo bg-img-boutique-01 flex min-h-screen w-screen flex-col items-center justify-center bg-cover bg-center bg-no-repeat"
        >
            <h1
                :class="[
                    'text-angus drop-shadow-lg',
                    'text-[12px] sm:text-[16px] md:text-[24px]',
                ]"
            >
                <div class="flex items-center">
                    <div class="slogan-font-martellas mr-[0.125em] mt-[-0.5em] text-[3.6em]">
                        Une
                    </div>
                    <div class="slogan-font-ragata text-[4.5em]">
                        foule
                    </div>
                    <div class="slogan-font-martellas ml-[0.125em] mt-[-0.5em] text-[3.6em]">
                        de
                    </div>
                </div>

                <div class="relative flex">
                    <div class="slogan-font-ragata text-[5.2em]">
                        saveurs
                    </div>
                </div>

                <div class="flex items-center">
                    <div class="mr-[0.5em] mt-[-1.375em] flex flex-col">
                        <div class="slogan-font-martellas text-center text-[1.6em]">
                            tout
                        </div>
                        <div class="slogan-font-martellas mt-[-0.375em] text-[3em]">
                            droit
                        </div>
                    </div>
                    <div class="slogan-font-ragata text-[3.8em]">
                        sorties
                    </div>
                    <div class="slogan-font-martellas ml-[0.125em] mt-[-0.5em] text-[3.2em]">
                        du
                    </div>
                </div>

                <div class="flex items-baseline">
                    <span class="slogan-font-ragata relative text-[3em]">
                        congélateur
                    </span>
                    <img
                        alt="Go"
                        class="ml-[0.125em] w-[1em]"
                        src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704747535/praticofrigo/praticofrigo-go-dark_lzro08.svg"
                    >
                </div>
            </h1>

            <div
                :class="[
                    'mt-6 flex items-center gap-6',
                ]"
            >
                <a
                    class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                    :href="siteRoute('/#produits')"
                >
                    Produits
                </a>
                <a
                    class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                    :href="siteRoute('/#contact')"
                >
                    Contact
                </a>
            </div>
        </section>

        <section
            id="produits"
            :class="[
                'bg-mayo flex scroll-mt-18 flex-col items-center justify-start',
                'gap-12 py-12 md:gap-24 md:py-24'
            ]"
        >
            <div class="text-center">
                <h3 class="text-ketchup text-xl font-black uppercase leading-none">
                    Nos Produits
                </h3>
                <h4 class="leading-none">
                    <span
                        :class="[
                            'font-[ragata] uppercase',
                            'text-2xl md:text-4xl'
                        ]"
                    >
                        Petits prix,
                    </span>
                    <wbr>
                    <span
                        :class="[
                            'text-mustard font-[martellas]',
                            'text-4xl md:text-6xl',
                        ]"
                    >
                        sans compromis.
                    </span>
                </h4>
            </div>

            <div
                :class="[
                    'container grid',
                    'grid-cols-1 gap-6 md:gap-12 lg:grid-cols-3',
                ]"
            >
                <div class="flex flex-col rounded-xl shadow-xl">
                    <figure class="aspect-picture">
                        <img
                            class="size-full rounded-t-xl object-cover"
                            src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704745128/praticofrigo/praticofrigo-viandes-surgeles_o0ghxu.jpg"
                        >
                    </figure>

                    <div class="bg-mayo-light flex grow flex-col items-stretch gap-3 rounded-b-xl p-6 text-center">
                        <h5 class="text-3xl">
                            Viandes surgelées
                        </h5>
                        <p class="grow">
                            Chez PraticoFrigo, nous offrons une approche des viandes surgelées complètement repensée! Nos coupes de qualité supérieure sont préparées avec soin pour garantir une fraîcheur inégalée. Conservez-les au congélateur jusqu’à 6 mois et appréciez un plaisir gustatif prêt à être cuit sous vide, sans aucun compromis sur le goût.
                        </p>
                        <a
                            class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                            :href="siteRoute('/produits/viandes-surgelees')"
                        >
                            Voir ces produits
                        </a>
                    </div>
                </div>

                <div class="flex flex-col rounded-xl shadow-xl">
                    <figure class="aspect-picture">
                        <img
                            class="size-full rounded-t-xl object-cover"
                            src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704745127/praticofrigo/praticofrigo-mets-surgeles_nk0l9f.jpg"
                        >
                    </figure>

                    <div class="bg-mayo-light flex grow flex-col items-stretch gap-3 rounded-b-xl p-6 text-center">
                        <h5 class="text-3xl">
                            Mets surgelés
                        </h5>
                        <p class="grow">
                            Pas le temps de cuisiner? Simplifiez votre quotidien en choisissant parmi notre vaste sélection de repas et produits prêts-à-manger! Un rendez-vous entre la gastronomie et la commodité qui se conserve au congélateur et se cuisine rapidement. Nos délices surgelés sont conçus pour vous offrir une expérience culinaire simplifiée, sans aucun compromis sur la qualité.
                        </p>
                        <a
                            class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                            :href="siteRoute('/produits/mets-surgeles')"
                        >
                            Voir ces produits
                        </a>
                    </div>
                </div>

                <div class="flex flex-col rounded-xl shadow-xl">
                    <figure class="aspect-picture">
                        <img
                            class="size-full rounded-t-xl object-cover"
                            src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704745128/praticofrigo/praticofrigo-ventes-eclair_kiaer1.jpg"
                        >
                    </figure>

                    <div class="bg-mayo-light flex grow flex-col items-stretch gap-3 rounded-b-xl p-6 text-center">
                        <h5 class="text-3xl">
                            Ventes éclair
                        </h5>
                        <p class="grow">
                            Que vous ayez un événement de grande ampleur ou une grosse famille à nourrir, les ventes éclair vous offrent l’opportunité de vous approvisionner en lots copieux de produits de qualité, toujours frais et prêts à être cuisinés. Laissez-vous séduire par des mets délicieux qui plairont à tous les goûts, sans aucun compromis sur la quantité.
                        </p>
                        <a
                            class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                            :href="siteRoute('/produits/ventes-eclair')"
                        >
                            Voir ces produits
                        </a>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <a
                    class="bg-ketchup text-mayo rounded-xl px-6 py-4 text-lg font-bold uppercase hover:text-white"
                    :href="siteRoute('/produits')"
                >
                    Tous nos produits
                </a>
            </div>
        </section>

        <section
            id="a-propos"
            :class="[
                'bg-mayo flex scroll-mt-18 flex-col items-center justify-start',
                'gap-12 py-12 md:gap-24 md:py-24'
            ]"
        >
            <div class="text-center">
                <h3 class="text-ketchup text-xl font-black uppercase leading-none">
                    À propos
                </h3>
                <h4 class="leading-none">
                    <span
                        :class="[
                            'text-mustard font-[martellas]',
                            'text-3xl md:text-6xl',
                        ]"
                    >
                        Salut,
                    </span>
                    <span
                        :class="[
                            'font-[ragata] uppercase',
                            'text-2xl md:text-4xl'
                        ]"
                    >
                        moi c'est Guy!
                    </span>
                </h4>
            </div>

            <div
                :class="[
                    'container grid gap-12',
                    'grid-cols-1 lg:grid-cols-3',
                ]"
            >
                <div class="flex items-center">
                    <div class="bg-mayo-light grow rounded-xl shadow-xl">
                        <figure class="aspect-square lg:aspect-portrait">
                            <img
                                alt="Guy Benoit"
                                class="size-full rounded-xl object-cover"
                                src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1697498803/praticofrigo/guy-benoit_atgqob.jpg"
                            >
                        </figure>
                    </div>
                </div>

                <div class="flex items-center lg:col-span-2">
                    <div class="bg-mayo-light grow rounded-xl p-6 shadow-xl md:p-12">
                        <h6 class="mb-6 text-center text-3xl md:text-left">
                            Guy Benoit, boucher depuis 1985.
                        </h6>
                        <p class="mb-1.5 text-justify text-lg last:mb-0">
                            Ancien propriétaire de la Boucherie d’Antan de 2002 à 2022 et artisan-boucher de troisième génération, j’apporte avec moi plus de 38 ans d'expérience dans ce métier. Ma passion pour la boucherie ne connaît pas de limites, et c'est avec cette énergie que je m'efforce de vous servir.
                        </p>
                        <p class="mb-1.5 text-justify text-lg last:mb-0">
                            Fort de mes expertises variées et de mes connaissances approfondies des viandes et des méthodes bouchères, je m'engage à vous proposer une grande variété de produits de la plus haute qualité. Ma mission? Vous satisfaire pleinement en vous offrant des produits qui vont au-delà de vos attentes.
                        </p>
                        <p class="mb-1.5 text-justify text-lg last:mb-0">
                            Chez PraticoFrigo, chaque morceau de viande a une histoire et il me fait plaisir de la partager avec vous. Merci de faire partie de notre communauté gourmande et de permettre à ma passion de devenir la vôtre. On se régale ensemble!
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section
            id="contact"
            :class="[
                'bg-mayo flex scroll-mt-18 flex-col items-center justify-start',
                'gap-12 py-12 md:gap-24 md:py-24'
            ]"
        >
            <div class="text-center">
                <h3 class="text-ketchup text-xl font-black uppercase leading-none">
                    Contact
                </h3>
                <h4 class="leading-none">
                    <span
                        :class="[
                            'font-[ragata] uppercase',
                            'text-2xl md:text-4xl'
                        ]"
                    >
                        Comment
                    </span>
                    <span
                        :class="[
                            'text-mustard font-[martellas]',
                            'text-3xl md:text-6xl'
                        ]"
                    >
                        nous joindre?
                    </span>
                </h4>
            </div>

            <div
                :class="[
                    'container grid gap-12',
                    'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3',
                ]"
            >
                <div class="flex items-center">
                    <div class="bg-mayo-light grow rounded-xl shadow-xl">
                        <iframe
                            allowfullscreen
                            width="100%"
                            height="100%"
                            class="aspect-square rounded-xl"
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2778.379924290682!2d-73.7575189!3d45.863707999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8cf05549f0e2b%3A0x35aea3a81a7c8f2d!2s1042%20Rue%20Saint-Isidore%2C%20Saint-Lin%20-%20Laurentides%2C%20QC%20J5M%202V5!5e0!3m2!1sen!2sca!4v1697500056684!5m2!1sen!2sca"
                        />
                    </div>
                </div>

                <div class="flex items-center">
                    <div class="bg-mayo-light grow space-y-12 rounded-xl p-6 shadow-xl md:p-12">
                        <div>
                            <AspectIcon
                                class="text-angus size-8"
                                name="phone"
                            />
                            <p class="text-xl font-bold">
                                450 215-0200
                            </p>
                        </div>
                        <div>
                            <AspectIcon
                                class="text-angus size-8"
                                name="mail-send-envelope"
                            />
                            <p class="text-xl font-bold">
                                info@praticofrigo.com
                            </p>
                        </div>
                        <div>
                            <AspectIcon
                                class="text-angus size-8"
                                name="map-location"
                            />
                            <p class="text-xl">
                                1042 Saint-Isidore, <span class="font-bold">Saint-Lin-Laurentides</span>
                                <wbr>
                                (Québec) J5M 2V5
                            </p>
                        </div>
                    </div>
                </div>

                <div class="flex items-center lg:col-span-full xl:col-span-1">
                    <div class="bg-mayo-light grow rounded-xl p-6 shadow-xl md:p-12">
                        <AspectIcon
                            class="text-angus size-8"
                            name="calendar-schedule"
                        />
                        <h6 class="mb-6 text-2xl">
                            Heures d'ouverture
                        </h6>

                        <ul class="space-y-3 text-xl">
                            <li class="flex justify-between">
                                <span class="font-medium">
                                    Lundi
                                </span>
                                <span class="font-black">
                                    Fermé
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium">
                                    Mardi
                                </span>
                                <span class="font-black">
                                    09h00 - 18h00
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium">
                                    Mercredi
                                </span>
                                <span class="font-black">
                                    09h00 - 18h00
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium">
                                    Jeudi
                                </span>
                                <span class="font-black">
                                    09h00 - 18h00
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium">
                                    Vendredi
                                </span>
                                <span class="font-black">
                                    09h00 - 18h00
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium italic">
                                    Samedi
                                </span>
                                <span class="font-black">
                                    09h00 - 17h00
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium italic">
                                    Dimanche
                                </span>
                                <span class="font-black">
                                    Fermé
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script lang="ts" setup>
    import { useRoute } from '@/shared/composables/use-route.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    defineProps<{
        attributes: any[];
        collections: { handle: string, entries: any[] }[];
    }>();

    const { siteRoute } = useRoute();
</script>
